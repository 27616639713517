/* TODO Add site wide styles */
/* .title {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bolder;
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
} */
/* .title {
    font-weight: bold;
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
} */

/*
.navbar {
    border-bottom: 1px solid black;
    margin: 0 5%;
    align-items: center;
} */

/* .title-pic img {
    height: 2rem;
} */

/* .navbar {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
    margin: 0px 5% 0px 5%;
    align-items: center;
} */
/*
.login-icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
} */

/* button {
    background-color: antiquewhite;
} */

/* .today {
    font-size: small;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
} */

.modal-errors {
    font-size: smaller;
    color: red;
}

/* .modal-title {
    margin-top: 2vh;
    margin-bottom: 1vh;
} * */
/*
.modal-background {
    background-image: url("https://img.freepik.com/premium-photo/blank-space-text-with-vegetables-surrounding-color-background_756748-3505.jpg");
    background-position-y: bottom;
    background-position: center;
    background-size: cover;
    height: 75vh;
    width: 60vw;
    border: 2px solid black;
    display: flex;
    justify-content: center;
}

.modal-form {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    margin-bottom: 15vh;
    margin-top: 15vh;
    margin-right: 5%;
    width: 70%;
    height: 45vh;
}

.form-styling textarea {
    width: 100%;
    height: 10vh;
    resize: none;
}

.form-styling input {
    width: 100%;
    resize: none;
}

.modal-buttons {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-top: 1vh;
    margin-bottom: 1vh;
}

button {
    text-align: center;
    cursor: pointer;
} */

/*
.red-button {
    background-color: antiquewhite;
    color: red;
    border-radius: 10px;
}

.red-button:hover {
    background-color: rgb(218, 86, 86);
    color: white;
    font-weight: bold;
}

.green-button {
    background-color: antiquewhite;
    color: darkgreen;
    font-weight: bold;
    border-radius: 10px;
}

.green-button:hover {
    background-color: green;
    color: white;
    font-weight: bold;
}

.blue-button {
    background-color: antiquewhite;
    color: darkblue;
    border-radius: 10px;
    min-width: 4vw;
    font-weight: bold;
}

.blue-button button {
    text-align: center;
    padding: 300%;
    font-weight: bold;
}

.blue-button:hover {
    background-color: rgb(32, 77, 155);
    color: white;
    font-weight: bold; */
/* } */
/*
a {
    text-decoration: none;
    color: black;
}

a:hover {
    color: rgb(88, 88, 88);
}

.single-item-container {
    margin: 3% 5% 3% 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.single-item-container img {
    width: 50vw;
}

.single-details {
    max-width: 50vw;
    width: 70%;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: large;
}

.poster {
    font-size: larger;
    margin-top: 10px;
    width: 50vw;
}

.category-bar {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    margin: 0px 5% 0px 5%;
}

.profile-menu {
    display: flex;
    flex-direction: column;
}

.styling-line {
    border-bottom: 2px solid black;
    margin-bottom: 3px;
    margin-top: 3px;
}

.title-buttons {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.my-posts {
    width: 50vw;
}

.my-posts-container {
    display: flex;
    justify-content: center;
}

.remove-styling {
    text-decoration: none;
}

.category-title {
    font-size: larger;
}

.category-desc {
    margin-bottom: 20px;
    text-align: center;
    padding-bottom: 20px; */
/* font-size: larger; */
/* border-bottom: 1px solid rgba(0, 0, 0, 0.3); */
/* max-width: 50vw; */
/* } */

html {
    /* background-color: rgb(231, 196, 151); */
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: black;
}

.wrap-break {
    word-wrap: break-word;
}

/*
.name-holder {
    width: 50vw;
}

.name-size {
    font-size: larger;
    font-weight: 600;
}

.ingredients-home-title {
    padding: 5px 10px 5px 10px;
    box-sizing: border-box;
}

.recipe-home-title {
    padding: 5px 10px 5px 60px;
}

.comments-modal-container {
    height: 101vh;
    width: 30vw;
    position: fixed;
    right: 0%;
    top: 0%; */
/* background-image: url("https://i.pinimg.com/originals/65/ea/2f/65ea2fff87a448c8ce953facbdd7d2ca.jpg"); */
/* background-image: url("https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.wisc-online.com%2Fasset-repository%2Fgetfile%3Fid%3D932%26getType%3Dview&f=1&nofb=1&ipt=adc0c45d3856771c82139be0c1a78273e4e181e458ac3d34140b5058102e60a1&ipo=images"); */
/* background-position: center; */
/* background-size: cover; */
/* overflow: auto; */
/* transition: transform 0.5s ease-in-out; */
/* transform: translateX(100%); */
/* } */
/*
.slider {
    transform: translateX(0%);
}

.comments-modal-text {
    margin: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 700;

}

.comments-arr {
    border-bottom: 1px black solid;
}

.button-placer {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.posted-by {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: bolder;
}

.long-button {
    width: 50%;
} */

/* .single-button {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    width: 25vw;
} */

/* .comment-page-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.comment-page-title button {
    background: none;
    border: none;
    font-weight: bold;
    font-size: larger;
}

.border-base-comments {
    margin-bottom: 10vh;
} */
/*
.unavailable {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin: 10vh 0px 20vh 0px;
    padding: 0px 10vh 0px 10vh;
}

.placement {
    display: flex;
    flex-direction: row;
    width: 30vw;
    position: relative;
    right: 20%;
}

.orgs {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 3px;
}

.orgs button {
    margin-left: 20px;
    height: 25px;
}

.alignment {
    display: flex;
    max-width: 50vw;
    min-height: 10vh;
}

.relate-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 35%;
    overflow-y: scroll;
    overflow: auto;
} */

/*
.modal-org {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px; */
/* border: red 2px solid; */
/* } */

/* select {
    margin-bottom: 10px;
}

.selector-ingreds {
    border-left: 1px rgba(0, 0, 0, 0.2) solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: visible;
    width: 110%;
    font-size: smaller;
    max-width: 15vw;
}

.rmsc.multi-select {
    width: 100%;
} */

/*
.buttons-recipes {
    display: flex;
    margin: 3px;
    gap: 1rem;
} */
