/* .ingredient-display {
    font-size: medium;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    padding: 2px 10px 2px 10px;
    align-items: center;
    width: 100%;
    border-bottom: 1px black solid;
}
.ingredient-display img{
    width: 10vw;
    height: 10vh;
} */
/* .ingredient-display {
    font-size: medium;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    padding: 2px 10px;
    align-items: center;
    width: 100%;
    border-bottom: 1px black solid;
}

.ingredient-display img {
    width: 10vw;
    height: 10vh;
}

@media screen and (max-width: 768px) {
    .ingredient-display {
        flex-direction: column;
        padding: 10px;
        border-bottom: 1px solid #ddd;

    }

    .ingredient-display img {
        width: 100%;
        height: auto;
    }
} */
.ingredient-display {
    margin-bottom: 20px;
    /* Adjust as needed */
}

.ingredient-link {
    display: block;
    text-align: center;
    text-decoration: none;
    color: inherit;
}

.ingredient-image {
    width: 100%;
    height: 150px;
    /* Adjust as needed */
    object-fit: cover;
    border-radius: 8px;
    /* Add a rounded border */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    /* Add a subtle shadow */
}
