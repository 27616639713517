/* .profile-dropdown {
  background-image: url("https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.wisc-online.com%2Fasset-repository%2Fgetfile%3Fid%3D932%26getType%3Dview&f=1&nofb=1&ipt=adc0c45d3856771c82139be0c1a78273e4e181e458ac3d34140b5058102e60a1&ipo=images");
  background-position: bottom;
  background-size: cover;
  position: fixed;
  right: 0%;
  top: 0%;
  width: 30vw;
  background-color: white;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

/* .borders {
  margin-left: 1rem;
  margin-right: 1rem;
} */

.hidden {
  display: none;
}

.nav-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 3px;
}

/* .navbar {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  background-color: inherit;
  /* Adjust border color as needed */
/* margin: 0 5%;
  align-items: center;
} */
/* .title */
/* display: flex; */
/* justify-content: center; */
/* align-items: center; */
/* Center vertically */
/* padding: 1rem; */
/* Bulma uses rem for padding */
/* } */

/* Bulma uses rem for padding */
/* .today {
  display: flex;
  width: 150px;
  padding: 1rem;
} */

/* Bulma uses rem for spacing */
/* .button-sort {
  display: flex;
  gap: 1rem;
} */

/* Bulma uses rem for spacing */
/* .create-buttons {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
} */

/* Bulma uses rem for font size */
/* .exit-button {
  font-size: 1rem;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
}

.demo-exit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
} */

/* Bulma uses rem for padding */
/* Center vertically */

/* Mobile styles using Bulma classes */
/* @media screen and (max-width: 768px) {
  .title {
    background-color: #fff;
    /* Adjust background color as needed */

/*
  .today {
    display: flex;
    width: 100%;
    padding: 1rem;
  }

  .button-sort {
    flex-direction: column;
  }

  .create-buttons {
    width: 100%;

  }

  .demo-exit {
    display: flex;

    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
} */
